import axios from 'axios';

const getUserTokenData = async () => {
  const claims = await axios.get('api/me/claims');

  if (claims && claims.data && claims.data.data) {
    return claims.data.data;
  }

  return null;
};

const state = {
  user: {},
  accountId: null,
};

const getters = {
  isCustomer(state) {
    return Boolean(state.user && state.user.sub);
  },
  isEmployee(state) {
    return Boolean(state.user && state.user.emp);
  },
  isCustomerModeScan(state, _, rootState) {
    return Boolean(
      state.user && state.user.sub && state.user.scango && rootState.config.scanAndGoEnabled,
    );
  },
  isGuest(state) {
    return (state.user && !state.user.sub) || !state.user;
  },
  getUser(state) {
    return state.user;
  },
  getCustomer(state) {
    return state.user ? state.user.sub : null;
  },
  getEmployee(state) {
    return state.user ? state.user.emp : null;
  },
  getAccountId(state) {
    return state.user.num ? state.user.num : null;
  },
};

export const SET_USER = 'SET_USER';
export const SET_ACCOUNT_ID = 'SET_ACCOUNT_ID';

const mutations = {
  [SET_USER](state, user) {
    state.user = user;
  },
  [SET_ACCOUNT_ID](state, accountId) {
    state.user.num = accountId;
  },
};

const actions = {
  async setUserFromCookie({ commit }) {
    try {
      const user = await getUserTokenData();
      commit(SET_USER, user);
    } catch (error) {
      console.error(error);
    }
  },
  setAccountId({ commit }, payload) {
    try {
      commit(SET_ACCOUNT_ID, payload);
    } catch (error) {
      console.error(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
