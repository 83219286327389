import ModalService from '@/modules/modals/services/modal.service';
import WCAddToListModal from '@/modules/lists/components/WCAddToListModal/WCAddToListModal.vue';
import WCItemModifierRoot from '@/modules/itemModifiers/components/WCItemModifierRoot/WCItemModifierRoot.vue';

export default {
  data() {
    return {
      tabs: [],
      selectedTab: '',
    };
  },

  created() {
    this.updateProductTab();
  },
  methods: {
    /**
     * Method to get product details
     */
    updateProductTab() {
      if (
        this.item &&
        (this.item.nutrition || this.item.ingredients || this.nutritionCustomContent)
      ) {
        this.tabs.push('Nutrition');
      }
      if (this.item && this.item.customSources) {
        this.item.customSources
          .filter(
            cs => cs.label !== 'Details' && cs.label !== 'Nutrition' && cs.label !== 'Disclaimer',
          )
          .forEach(cs => {
            this.tabs.push(cs.label);
          });
      }

      if (this.hasTabArrangement) {
        this.tabs.unshift('Details'); // Default tab
        this.tabs.push('Disclaimer');
      }

      this.selectedTab = this.tabs[0];
    },
    async showLists() {
      try {
        await ModalService.open(WCAddToListModal, { item: this.item });
      } catch (error) {
        console.error(error);
      }
    },
    /**
     * Method to customize item inorder to add it to the list
     * @param {Boolean} isMobile
     */
    async customizeItemToAddToList(isMobile = false, fromMTOItemFavIcon = false) {
      if (!this.isGuest) {
        if (!isMobile) {
          try {
            await ModalService.open(WCItemModifierRoot, {
              item: this.item,
              hasAddToList: true,
              editingItem: false,
              favoriteMTOItem: fromMTOItemFavIcon,
              orderType: this.orderType,
            });
          } catch (error) {
            console.log(error);
          } finally {
            if (this.$refs.customizeBtnRef) this.$refs.customizeBtnRef.focus();
          }
        } else {
          this.$router.push({
            name: 'Customize Your Item',
            params: { id: this.item.id, name: this.item.name },
            query: { hasAddToList: true, favoriteMTOItem: fromMTOItemFavIcon },
          });
        }
      } else {
        this.$router.push('/login');
      }
    },
  },
  computed: {
    /**
     * Method to check if the product has modifiers
     * @returns Boolean - true | false
     */
    hasModifiers() {
      return this.item.hasModifiers;
    },
    detailsCustomContent() {
      return this.item.customSources?.find(c => c.label === 'Details');
    },
    nutritionCustomContent() {
      return this.item.customSources?.find(c => c.label === 'Nutrition');
    },
    disclaimerCustomContent() {
      return this.item.customSources?.find(c => c.label === 'Disclaimer');
    },
    customSourcesMinusDuplicates() {
      return this.item.customSources?.filter(
        c => c.label !== 'Details' && c.label !== 'Nutrition' && c.label !== 'Disclaimer',
      );
    },
  },
  watch: {
    /**
     * Method to update the product details Tab on product item change
     */
    item() {
      this.updateProductTab();
    },
  },
};
