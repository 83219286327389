import { render, staticRenderFns } from "./WCEmployeeSidebar.vue?vue&type=template&id=6f6362d1&scoped=true&"
import script from "./WCEmployeeSidebar.vue?vue&type=script&lang=js&"
export * from "./WCEmployeeSidebar.vue?vue&type=script&lang=js&"
import style0 from "./WCEmployeeSidebar.vue?vue&type=style&index=0&id=6f6362d1&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f6362d1",
  null
  
)

export default component.exports