<template>
  <section
    :class="{
      'wc-modal-root': modals.length > 0,
      'front-most-modal': modal && modal.props && modal.props.isFrontMostModal,
    }"
    @click.stop=""
  >
    <transition name="wc-modal">
      <component
        v-if="modal"
        :is="modal.component"
        v-bind:close="modal.close"
        v-bind:dismiss="modal.dismiss"
        v-bind:modalMessage="message"
        v-bind="modal.props"
      />
    </transition>
  </section>
</template>

<script>
import ModalService from '../../services/modal.service';
import WCModal from '../WCModal/WCModal.vue';

export default {
  components: { WCModal },
  data() {
    return {
      modals: [],
      message: null,
    };
  },
  created() {
    ModalService.$on('open', ({ component, props, resolve, reject }) => {
      this.modals.push({
        component,
        props,
        close: value => {
          this.modals = this.modals.filter(modal => modal.component !== component);
          this.message = value; // To Share message between multiple modals
          resolve(value);
        },
        dismiss: reason => {
          this.modals = this.modals.filter(modal => modal.component !== component);
          this.message = null;
          reject(reason);
        },
      });
    });
    ModalService.$on('dismissAll', () => {
      this.modals.forEach(modal => modal.dismiss('Closing all modals'));
    });
  },
  computed: {
    modal() {
      return this.modals[this.modals.length - 1];
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

.wc-modal-root {
  position: fixed;
  top: 0;
  z-index: map-get($zindex, 'modals');
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.2);

  &.front-most-modal {
    z-index: map-get($zindex, 'front-most-modals');
  }
}

.wc-modal-enter-active,
.wc-modal-leave-active {
  transition: all 0.25s ease-out;
}

.wc-modal-enter,
.wc-modal-leave-to {
  transform: translateY(25px);
  opacity: 0;
}
</style>
