/**
 * Desktop search and mobile search data and methods
 */

import { DEFAULT_DEBOUNCE_TIME_LIMIT } from '@/constants/AppConstants';
import { debounce } from 'lodash';
import OrderTypesMixin from '@/modules/orderTypes/mixins/OrderTypesMixin';

export default {
  props: {
    itemGroups: {
      required: false,
      type: Array,
    },
    suggestions: {
      required: false,
      type: Array,
    },
  },
  mixins: [OrderTypesMixin],
  data() {
    return {
      selectedItemGroup: '',
      showSuggestions: false,
      searchInput: '',
      activeSuggestionIndex: -1,
      departmentSelected: '',
      hasFocus: false,
    };
  },
  computed: {
    isClearSearchAvailable() {
      return this.searchInput.length > 0;
    },
  },
  methods: {
    /**
     * Method to perform submit search action on input event
     * @param {String} searchTerm
     * @param {String} department
     */
    submitSearch(searchTerm, department) {
      const itemGroup = this.selectedItemGroup === '-1' ? undefined : this.selectedItemGroup;
      if (itemGroup?.startsWith('WOT-')) {
        this.setActiveOrderType(this.orderTypeObjectFromId(itemGroup));
      }
      this.searchInput = searchTerm;
      if (this.activeSuggestionIndex !== -1 && this.suggestions[this.activeSuggestionIndex]) {
        this.searchInput = this.suggestions[this.activeSuggestionIndex].name;
      }
      const term = this.searchInput ? this.searchInput.toLowerCase() : undefined;
      this.$emit('search', {
        term,
        department,
        itemGroup,
      });
      this.hideModal();
    },
    /**
     * Method performed on input focus
     * @param {String} searchInput
     */
    focusInSearch(searchInput) {
      this.hasFocus = true;
      this.fetchSuggestions(searchInput);
    },
    /**
     * Method to show modal
     */
    showModal() {
      this.hasFocus = true;
      this.showSuggestions = true;
    },
    /**
     * Method to hide modal
     */
    hideModal() {
      this.hasFocus = false;
      this.activeSuggestionIndex = -1;
      this.showSuggestions = false;
    },
    /**
     * Method to fetch suggestion based on the search term
     */
    fetchSuggestions: debounce(function(term) {
      this.activeSuggestionIndex = -1;
      if (term) {
        this.$emit('fetchSuggestions', term.toLowerCase());
      }
    }, DEFAULT_DEBOUNCE_TIME_LIMIT),
    /**
     * Method to perform key down event
     */
    handleKeyDown() {
      if (!this.suggestions) {
        return;
      }

      this.activeSuggestionIndex = (this.activeSuggestionIndex + 1) % this.suggestions.length;
    },
    /**
     * Method to perform key up event
     */
    handleKeyUp() {
      if (!this.suggestions) {
        return;
      }

      if (this.activeSuggestionIndex <= 0) {
        this.activeSuggestionIndex = this.suggestions.length - 1;
      } else {
        this.activeSuggestionIndex -= 1;
      }
    },
    /**
     * Method to perform escape key event
     */
    handleEscapeKey() {
      this.hideModal();
    },
    itemGroupSelected(department) {
      this.selectedItemGroup = department;
    },
    onClickClearIcon() {
      this.searchInput = '';
      this.focusInput();
    },
    focusInput() {
      this.$refs.searchInput.$el.focus();
    },
  },
  /**
   * Watcher - whenever new suggestion is fetched, modal is shown
   */
  watch: {
    suggestions: {
      handler() {
        this.showModal();
      },
    },
  },
};
