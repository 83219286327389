import { render, staticRenderFns } from "./WCItemGroupsMenu.vue?vue&type=template&id=2cfb5a36&scoped=true&"
import script from "./WCItemGroupsMenu.vue?vue&type=script&lang=js&"
export * from "./WCItemGroupsMenu.vue?vue&type=script&lang=js&"
import style0 from "./WCItemGroupsMenu.vue?vue&type=style&index=0&id=2cfb5a36&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2cfb5a36",
  null
  
)

export default component.exports