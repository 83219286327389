<template>
  <div>
    <platform-renderer>
      <template v-slot:onMobile>
        <WCCarousel class="wc-carousel px-3">
          <!-- START : Mobile Category List -->
          <div class="mobile-category-list nav nav-pills" id="wc-tab" role="tablist">
            <!-- START : Item Modifier Item Categories -->
            <WCItemModifierCategory
              v-for="category in categories"
              :key="category.id"
              :value="category"
              :category="category"
              :selectedAnswers="getSelectedItemsByCategory(category)"
              class="wc-category nav-item"
              :class="{
                'wc-category-active': value.id === category.id,
                'wc-category-inactive': value.id !== category.id,
              }"
              @categorySelected="categorySelected(category)"
              criteriaIconColor="red"
            />
            <!-- END : Item Modifier Item Categories -->
            <!-- START : Item Modifier Linked Item Categories -->
            <WCItemModifierLinkedItems
              v-if="itemModifiers.linkedItems"
              :linkedItems="itemModifiers.linkedItems"
              class="wc-category nav-item"
              :class="{
                'wc-category-active': value.id === 1,
                'wc-category-inactive': value.id !== 1,
              }"
              @linkedItemsSelected="linkedItemsSelected"
            />
            <!-- END : Item Modifier Linked Item Categories -->
          </div>
          <!-- END : Mobile Category List -->
        </WCCarousel>
      </template>
      <template v-slot:onDesktop>
        <transition-group
          name="wc-category-list"
          tag="div"
          class="category-list nav nav-pills flex-column p-2 text-white"
          id="wc-tab"
          role="tablist"
          aria-orientation="vertical"
          :aria-label="$t('customizeYourItem', { itemName: itemName })"
        >
          <WCItemModifierCategory
            v-for="category in categories"
            :key="category.id"
            :value="category"
            :category="category"
            :selectedAnswers="getSelectedItemsByCategory(category)"
            class="wc-category mb-3 nav-item"
            :class="{
              'wc-category-active': value.id === category.id,
              'wc-category-inactive': value.id !== category.id,
            }"
            @categorySelected="categorySelected(category)"
            :id="constructCategoryId(category.name, category.id) + 'tab'"
            :aria-selected="value.id === category.id ? 'true' : 'false'"
            :aria-controls="constructCategoryId(category.name, category.id) + 'tabpanel'"
          />
          <WCItemModifierLinkedItems
            v-if="itemModifiers.linkedItems"
            :linkedItems="itemModifiers.linkedItems"
            class="wc-category mb-3 nav-item"
            :class="{
              'wc-category-active': value.id === 1,
              'wc-category-inactive': value.id !== 1,
            }"
            @linkedItemsSelected="linkedItemsSelected"
            :key="itemModifiers.linkedItems.id"
            :id="
              constructCategoryId(itemModifiers.linkedItems.name, itemModifiers.linkedItems.id) +
                'tab'
            "
            :aria-selected="value.id === 1 ? 'true' : 'false'"
            :aria-controls="
              constructCategoryId(itemModifiers.linkedItems.name, itemModifiers.linkedItems.id) +
                'tabpanel'
            "
          />
          <WCItemModifierInstructions
            class="wc-category nav-item"
            :class="{
              'wc-category-active': value.id === -1,
              'wc-category-inactive': value.id !== -1,
            }"
            @specialInstructionsSelected="specialInstructionsSelected"
            v-if="isInstructionsPerItem"
            key="special-instructions"
            :aria-selected="value.id === -1 ? 'true' : 'false'"
            id="special-instructions-tab"
            aria-controls="special-instructions-tabpanel"
          />
        </transition-group>
      </template>
    </platform-renderer>
  </div>
</template>

<script>
import WCItemModifierCategory from '@/modules/itemModifiers/components/WCItemModifierCategory/WCItemModifierCategory.vue';
import WCItemModifierInstructions from '@/modules/itemModifiers/components/WCItemModifierInstructions/WCItemModifierInstructions.vue';
import ItemModifierMixin from '@/modules/itemModifiers/mixins/ItemModifierMixin';
import WCCarousel from '@/components/WCCarousel/WCCarousel.vue';
import PlatformRenderer from '@/modules/platform/components/PlatformRenderer';
import WCItemModifierLinkedItems from '@/modules/itemModifiers/components/WCItemModifierLinkedItems/WCItemModifierLinkedItems.vue';

export default {
  components: {
    WCItemModifierCategory,
    WCItemModifierInstructions,
    WCItemModifierLinkedItems,
    WCCarousel,
    PlatformRenderer,
  },
  name: 'WCItemModifierCategoryGroup',
  props: {
    value: {
      type: Object,
    },
    itemName: {
      type: String,
    },
  },
  mixins: [ItemModifierMixin],
  methods: {
    categorySelected(category) {
      this.$emit('input', category);
    },
    specialInstructionsSelected() {
      this.$emit('input', { id: -1 });
    },
    linkedItemsSelected() {
      this.$emit('input', { id: 1 });
    },
    /**
     * Method to get selected items in the category
     */
    getSelectedItemsByCategory(category) {
      const active = [];

      Object.values(this.currentAnswers).forEach(a => {
        if (a.quantity > 0) {
          category.items.forEach(i => {
            if (a.id === i.id) {
              active.push(a);
            }
          });
        }
      });

      return active;
    },
  },
  computed: {
    isInstructionsPerItem() {
      return this.$configuration.instructionsPerItem;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

/*----- START : Category List Wrap -----*/
::v-deep .wc-carousel.wc-carousel--snap {
  scroll-snap-type: none;
}
.mobile-category-list {
  button:first-of-type {
    margin-left: 0;
  }
  .wc-category {
    border-bottom: 3px solid transparent;
    border-left: 2px solid transparent;
    border-right: 2px solid transparent;
    border-radius: 0;
    margin-left: $margin-2;
    margin-right: $margin-2;
    vertical-align: bottom;

    &-active {
      font-weight: bold;
      color: var(--primary, $primary);
      border-bottom-color: var(--primary, $primary);
    }
  }
}
/*----- END : Category List Wrap -----*/
.category-list {
  margin-right: 0;
  margin-bottom: $margin-2 * 1.25;
  .wc-category {
    border-radius: 0.1em;
    width: 100%;
    border: 0;
    text-align: center;
  }
  .wc-category-list-enter-active,
  .wc-category-list-leave-active {
    transition: transform 0.8s ease-in;
  }

  /* Transition enter and leave */
  .wc-category-list-enter,
  .wc-category-list-leave-to {
    transform: translateY(-30%);
    transition: transform 0.8s ease-in;
  }

  .wc-category-active {
    position: relative;
    background: var(--primary, $primary);
  }

  .wc-category-active::after {
    left: 100%;
    top: 50%;
    border: solid transparent;
    content: '';
    position: absolute;
    pointer-events: none;
    border-left-color: var(--primary, $primary);
    border-width: 1.2em;
    margin-top: -$margin-3 * 1.2;
  }

  .wc-category-inactive {
    background: var(--gray);
  }
}
</style>
