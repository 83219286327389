import { render, staticRenderFns } from "./WCTextArea.vue?vue&type=template&id=58e4414a&scoped=true&"
import script from "./WCTextArea.vue?vue&type=script&lang=js&"
export * from "./WCTextArea.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58e4414a",
  null
  
)

export default component.exports