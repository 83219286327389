import { mapActions } from 'vuex';
import TrackableEventConstants from '@/constants/TrackableEventConstants';
import AnalyticsService from '@/services/analytics.service';

export default {
  methods: {
    ...mapActions({
      addListItem: 'lists/addListItem',
      addListItems: 'lists/addListItems',
    }),
    async addItemToList(payload) {
      const { item } = payload;
      AnalyticsService.track(TrackableEventConstants.ItemAddedToList, { items: [item] });
      return this.addListItem(payload);
    },
    async addItemsToList(payload) {
      AnalyticsService.track(TrackableEventConstants.ItemAddedToList, { items: payload.items });
      return this.addListItems(payload);
    },
  },
};
