import { mapActions, mapGetters } from 'vuex';
import { BigNumber } from 'bignumber.js';
import WCSimpleToast from '@/modules/toasts/components/WCSimpleToast/WCSimpleToast.vue';
import ToastService from '@/modules/toasts/services/ToastService';
import WCSimpleActionableToast from '@/modules/toasts/components/WCSimpleActionableToast/WCSimpleActionableToast.vue';
import UserMixin from '@/modules/user/mixins/UserMixin';
import TrackableEventConstants from '@/constants/TrackableEventConstants';
import AnalyticsService from '@/services/analytics.service';
import axios from 'axios';
import CartItemControls from '@/modules/cart/mixins/CartControls/CartItemControls';

export default {
  props: {
    item: {
      type: Object,
    },
    editingItem: {
      type: Boolean,
    },
    variation: {
      type: String,
    },
    cartInstructions: {
      type: String,
    },
  },
  mixins: [CartItemControls, UserMixin],
  computed: {
    ...mapGetters({
      isSyncing: 'cart/isSyncing',
      isGiftCard: 'cart/isGiftCard',
      getUser: 'user/getUser',
      isCustomerModeScan: 'user/isCustomerModeScan',
      cartsCount: 'cart/getCartsCount',
      nonEmptyCarts: 'cart/nonEmptyCarts',
      cartOrderType: 'cart/getCartOrderType',
      isInStore: 'cart/getInStore',
    }),
  },
  methods: {
    ...mapActions({
      setItemQuantity: 'cart/setItemQuantity',
      getCarts: 'cart/getCarts',
      setCurrentCart: 'cart/setCurrentCart',
      setCartsLoadingStatus: 'cart/setCartsLoadingStatus',
      removeEGiftCard: 'cart/removeGiftCard',
    }),
    /**
     * The rounded quantity set by the backend.
     * @return {BigNumber}
     */
    quantity(item, variation) {
      const lineItem = this.getItemLineItem(item, variation);
      return new BigNumber(lineItem ? lineItem.quantity : 0);
    },

    /**
     * The quantity increment value (depends on context).
     * @return {BigNumber}
     */
    step(item) {
      if (this.isSoldByAverageWeight(item)) {
        return new BigNumber(item.averageWeight);
      }

      if (item.weightProfile) {
        return new BigNumber(item.weightProfile.selectIncrement);
      }

      return new BigNumber(1);
    },

    /**
     * The computed quantity
     * @return {BigNumber}
     */
    getComputedQuantity(item, variation) {
      if (this.isSoldByAverageWeight(item)) {
        const quantity = new BigNumber(this.quantity(item, variation));
        const averageWeight = new BigNumber(item.averageWeight);
        return quantity.dividedBy(averageWeight).integerValue(BigNumber.ROUND_HALF_UP);
      }

      return new BigNumber(this.quantity(item, variation));
    },

    /**
     * The quantity to display to the UI.
     * @return {string}
     */
    getDisplayQuantity(item, variation) {
      const wp = item.weightProfile;
      if (this.isSoldByAverageWeight(item)) {
        return this.getComputedQuantity(item, variation).toString();
      }

      // For an item with a weight profile, display with trailing zeroes as specified in weight profiles' decimal property if the item's quantity has decimal places, else display the quantity as a whole number.
      if (wp) {
        return this.getComputedQuantity(item, variation)
          .modulo(1)
          .isZero()
          ? `${this.getComputedQuantity(item, variation).toString()} ${wp.abbrv}`
          : `${this.getComputedQuantity(item, variation).toFixed(
              wp.decimals,
              BigNumber.ROUND_HALF_UP,
            )} ${wp.abbrv}`;
      }

      return this.getComputedQuantity(item, variation).toString();
    },

    /**
     * The minimum quantity of an item that can be added to the cart.
     * @return {BigNumber}
     */
    getMinimumQuantity(item) {
      if (this.isSoldByAverageWeight(item)) {
        return new BigNumber(item.averageWeight);
      }

      if (item.weightProfile) {
        return new BigNumber(item.weightProfile.selectMinimum);
      }

      return new BigNumber(1);
    },

    /**
     * The maximum quantity of an item that can be added to the cart.
     * @return {BigNumber}
     */
    getMaximumQuantity(item) {
      let max = new BigNumber('Infinity');

      if (!this.$configuration.sellOutOfStock) {
        let maxBaseQuantity = item.maxBaseQuantity;
        if (item.ascQuantity > 0) {
          maxBaseQuantity = item.maxBaseQuantity / item.ascQuantity;
        }
        max = BigNumber.minimum(maxBaseQuantity, max);
        return max;
      }
      max = BigNumber.minimum(item.maxQuantity, max);
      return max;
    },

    /**
     * Calculates the total quantity of a given vendor group in the cart.
     * @return {BigNumber}
     */
    getTotalQuantityForVendorGroup(item) {
      const lineItems = this.getLineItemsOfType(1);
      const vendorGroup = item.quantityLimitData.vendorGroup;
      let quantity = new BigNumber(0);
      if (vendorGroup && lineItems.length) {
        lineItems.forEach(lineItem => {
          const qld = lineItem.item ? lineItem.item.quantityLimitData : null;
          if (qld && qld.vendorGroup === vendorGroup) {
            const packQty = new BigNumber(
              this.getCalculatedPackQuantity(qld, lineItem.item, lineItem.quantity),
            );
            quantity = packQty.plus(quantity);
          }
        });
      }
      return quantity;
    },

    /**
     * Whether or not we can increment again without violating the quantity limit.
     * @return {boolean}
     */
    isAddableWithQuantityLimit(item) {
      const qld = item.quantityLimitData;
      if (qld) {
        const packQty = this.getCalculatedPackQuantity(qld, item, 1);
        const total = packQty.plus(this.getTotalQuantityForVendorGroup(item));
        return total.comparedTo(qld.quantityLimit) <= 0;
      }

      // Quantity limit doesn't apply
      return true;
    },

    /**
     * Calculates the exact, unrounded quantity, given the rounded quantity and the number of
     * increments.
     * @return {BigNumber}
     */
    getExactQuantity(item, variation) {
      if (item.weightProfile) {
        return this.getNumIncrements(item, variation).comparedTo(0) === 0
          ? new BigNumber(0)
          : this.getMinimumQuantity(item).plus(
              this.getNumIncrements(item, variation)
                .minus(1)
                .multipliedBy(this.step(item)),
            );
      }

      return this.getNumIncrements(item, variation).multipliedBy(this.step(item));
    },

    /**
     * Calculates the number of increments that have occurred.
     *
     * The number of increments obeys this equation for a weight profile:
     * quantity = min + (numIncrements - 1) * incrementVal, numIncrements > 0
     * quantity = 0, numIncrements == 0
     *
     * Solved for numIncrements, we have:
     * numIncrements = (quantity - min)/incrementVal + 1, quantity > 0
     * numIncrements = 0, quantity == 0
     *
     * Otherwise, it obeys this equation:
     * quantity = numIncrements * incrementVal
     *
     * The number of increments is the same amount of times the user would need to consecutively
     * press the add button to achieve the current quantity (beginning with quantity = 0).
     * @return {BigNumber}
     */
    getNumIncrements(item, variation) {
      if (item.weightProfile) {
        return this.quantity(item, variation).comparedTo(0) === 0
          ? new BigNumber(0)
          : this.quantity(item, variation)
              .minus(this.getMinimumQuantity(item))
              .dividedBy(this.step(item))
              .integerValue(BigNumber.ROUND_HALF_UP)
              .plus(1);
      }

      return this.quantity(item, variation)
        .dividedBy(this.step(item))
        .integerValue(BigNumber.ROUND_HALF_UP);
    },

    /**
     * The next quantity after an increment.
     * @return {BigNumber}
     */
    getNextIncrement(item, variation) {
      return this.getExactQuantity(item, variation).comparedTo(0) === 0
        ? this.getMinimumQuantity(item)
        : this.getExactQuantity(item, variation).plus(this.step(item));
    },

    /**
     * The next quantity after a decrement.
     * @return {BigNumber}
     */
    getNextDecrement(item, variation) {
      return this.getExactQuantity(item, variation).comparedTo(this.getMinimumQuantity(item)) === 0
        ? new BigNumber(0)
        : this.getExactQuantity(item, variation).minus(this.step(item));
    },

    /**
     * Whether or not the next increment violates either max quantity or the quantity limit.
     * @return {boolean}
     */
    isNextIncrementValid(item, variation) {
      if (!this.isCustomerModeScan) {
        return (
          this.getNextIncrement(item, variation).comparedTo(this.getMaximumQuantity(item)) <= 0 &&
          this.isAddableWithQuantityLimit(item)
        );
      }

      return this.getNextIncrement(item, variation) && this.isAddableWithQuantityLimit(item);
    },

    /**
     * Whether or not the next decrement is valid (must either be zero or satisfy the minimum).
     * @return {boolean}
     */
    isNextDecrementValid(item, variation) {
      return (
        this.getNextDecrement(item, variation).comparedTo(0) === 0 ||
        this.getNextDecrement(item, variation).comparedTo(this.getMinimumQuantity(item)) >= 0
      );
    },

    /**
     * Whether or not the item is sold by average weight.
     * @return {boolean}
     */
    isSoldByAverageWeight(item) {
      return item.averageWeight >= 0;
    },

    /**
     * Whether or not the item is in stock.
     * @return {boolean}
     */
    isInStock(item) {
      // If the item is a service item i.e. maxBaseQuantity is not present, mark the quantity as in stock
      if (this.$configuration.sellOutOfStock || item.maxBaseQuantity === undefined) {
        return true;
      }

      return this.getMaximumQuantity(item).comparedTo(this.getMinimumQuantity(item)) >= 0;
    },

    /**
     * Whether or not the item is sold in stores only.
     * @return {boolean}
     */
    isInStoreOnly(item) {
      return item.maxQuantity <= 0 || item.actualPrice < 0;
    },
    /**
     * Pure function that calculates the pack quantity.
     * @param {object} qld
     * @param {object} item
     * @param {number|string|BigNumber} quantity
     * @return {BigNumber}
     */
    getCalculatedPackQuantity(qld, item, quantity) {
      if (qld) {
        const ascMultiple = new BigNumber((item && item.ascQuantity) || 1);
        return ascMultiple.multipliedBy(quantity).multipliedBy(qld.comboPackAmount);
      }

      return new BigNumber(0);
    },

    /**
     * Whether or not the given value is a valid quantity. Considers the effect on the current cart
     * if value were to become this item's new quantity.
     * @param {number|string|BigNumber} value
     * @return {boolean} true for valid, false otherwise
     */
    isValueValidQuantityForCart(value, item) {
      const theValue = new BigNumber(value);
      /* Zero is valid */
      if (theValue.comparedTo(0) === 0) {
        return true;
      }

      /* Between zero and min is invalid */
      if (
        theValue.comparedTo(this.getMinimumQuantity(item)) < 0 ||
        theValue.comparedTo(this.getMaximumQuantity(item)) > 0
      ) {
        return false;
      }

      /* If sold by average weight, must be a multiple of the average weight */
      if (this.isSoldByAverageWeight(item)) {
        return theValue.modulo(item.averageWeight).comparedTo(0) === 0;
      }

      /* If a weight profile present, must be multiple of the increments after adding the minimum */
      const wp = item.weightProfile;
      if (wp) {
        return (
          theValue
            .minus(wp.selectMinimum)
            .modulo(wp.selectIncrement)
            .comparedTo(0) === 0
        );
      }

      /* New value must not cause the cart to exceed quantity limit for this item's vendor group */
      const qld = item.quantityLimitData;
      if (qld) {
        const newItemQuantity = this.getCalculatedPackQuantity(qld, item, value);
        const oldItemQuantity = this.getCalculatedPackQuantity(
          qld,
          item,
          this.getExactQuantity(item),
        );
        const oldTotalForVendorGroup = this.getTotalQuantityForVendorGroup(item);
        const newTotalForVendorGroup = oldTotalForVendorGroup
          .minus(oldItemQuantity)
          .plus(newItemQuantity);
        if (newTotalForVendorGroup.comparedTo(qld.quantityLimit) > 0) {
          return false;
        }
      }

      /* Not sold by average weight and no weight profile means the quantity must be an integer */
      return theValue.isInteger();
    },

    /**
     * Sets the new quantity as long as it is valid.
     * @param {number|BigNumber} quantity
     */
    async setQuantity(quantity, item, variation, priceEmbeddedLineItem) {
      const theQuantity = this.ensureNumber(quantity);

      if (!this.isValueValidQuantityForCart(theQuantity, item) && !this.isCustomerModeScan) {
        throw Error(`Quantity ${theQuantity} is invalid for item ${item.id}`);
      }

      if (this.isGiftCard) {
        ToastService.open(WCSimpleToast, {
          props: {
            variant: 'danger',
            title: 'Cannot Add Item to Cart',
            message: this.$t('giftCardCartAlert'),
          },
          timeout: 7000,
        });
      }

      /* Warn if they aren't shopping their home store and their cart was empty */
      if (
        this.isEmpty &&
        theQuantity > 0 &&
        this.getUser?.home !== this.$configuration.store.id &&
        this.$configuration.multistore &&
        !this.isCustomerModeScan
      ) {
        ToastService.open(WCSimpleActionableToast, {
          props: {
            variant: 'primary',
            title: this.$t('storeLocation'),
            customIcon: ['fas', 'location-exclamation'],
            message: this.$t('checkShoppingAtCorrectStore', {
              storeName: `<span class="font-weight-bold">${this.$configuration.store.name}</span>`,
            }),
            btnList: this.getUser?.home
              ? [
                  { actionName: 'goHome', text: this.$t('goToHomeStore') },
                  {
                    actionName: 'updateHomeStore',
                    text: this.$t('makeHomeStore'),
                    isOutlineBtn: true,
                  },
                ]
              : [
                  { actionName: 'selectStorePage', text: this.$t('viewStoreLocations') },
                  {
                    actionName: 'updateHomeStore',
                    text: this.$t('makeHomeStore'),
                    isOutlineBtn: true,
                  },
                ],
          },
          actions: {
            goHome: () => {
              if (this.getUser) {
                this.$router.push(`/redirect-to/${this.getUser.home}`);
              } else {
                this.$router.push('/select-store');
              }
            },
            updateHomeStore: () => {
              this.setUserHomeStore(this.$configuration.store.id);
            },
            selectStorePage: () => {
              this.$router.push('/select-store');
            },
          },
        });
      }

      let sellByEachQty;

      if (this.isSoldByAverageWeight(item)) {
        const averageWeight = new BigNumber(item.averageWeight);
        sellByEachQty = BigNumber(theQuantity)
          .dividedBy(averageWeight)
          .integerValue(BigNumber.ROUND_HALF_UP);
      }

      AnalyticsService.track(
        theQuantity > 0
          ? TrackableEventConstants.ItemAddedToCart
          : TrackableEventConstants.ItemRemovedFromCart,
        { items: [item] },
      );
      const itemLineItem = this.getItemLineItem(item, variation);
      await this.setItemQuantity({
        itemLineItem,
        item,
        variation,
        weightedItemQuantity: sellByEachQty,
        quantity: theQuantity,
        priceEmbeddedLineItem,
        orderType: this.orderType,
      });
    },

    /**
     * Increments the quantity as long as it is possible.
     */
    async increment(item, variation, priceEmbeddedLineItem) {
      const initialQuantity = this.getDisplayQuantity(item, variation);
      if (this.isNextIncrementValid(item, variation)) {
        await this.setQuantity(
          this.getNextIncrement(item, variation),
          item,
          variation,
          priceEmbeddedLineItem,
        );
      }

      const updatedQuantity = this.getDisplayQuantity(item, variation);
      if (
        this.isCustomerModeScan &&
        !parseInt(initialQuantity, 10) &&
        parseInt(updatedQuantity, 10)
      ) {
        ToastService.open(WCSimpleToast, {
          props: {
            variant: 'success',
            title: 'Cart',
            message: 'Item added to your Cart',
          },
          timeout: 2000,
        });
      }

      if (!this.isNextIncrementValid(item, variation)) {
        ToastService.open(WCSimpleToast, {
          props: {
            variant: 'warning',
            title: 'Inventory',
            message: 'Maximum quantity reached',
          },
          timeout: 7000,
        });
      }
    },

    /**
     * Decrements the quantity as long as it is possible.
     */
    decrement(item, variation) {
      if (this.isNextDecrementValid(item, variation)) {
        this.setQuantity(this.getNextDecrement(item, variation), item, variation, null);
      }
    },

    /**
     * Removes the quantity, no ifs ands or buts.
     */
    async remove(item, variation) {
      AnalyticsService.track(TrackableEventConstants.ItemRemovedFromCart, { items: [item] });

      if (
        !this.$configuration.orderTypesEnabled ||
        (this.$configuration.orderTypesEnabled && this.isCustomerModeScan)
      ) {
        const itemLineItem = this.getItemLineItem(item, variation);
        await this.setItemQuantity({
          itemLineItem,
          item,
          variation,
          quantity: 0,
          orderType: this.orderType,
        });
      }

      if (
        (this.$configuration.orderTypesEnabled && this.lineItemCount() > 0) ||
        (this.$configuration.orderTypesEnabled && this.nonEmptyCarts?.length <= 1)
      ) {
        this.setCartsLoadingStatus(true);
        await this.setItemQuantity({
          item,
          variation,
          quantity: 0,
        });
        await this.getCarts();
        this.setCartsLoadingStatus(false);
      } else if (
        this.$configuration.orderTypesEnabled &&
        this.lineItemCount() === 0 &&
        this.nonEmptyCarts?.length > 1
      ) {
        this.setCartsLoadingStatus(true);
        await this.setItemQuantity({
          item,
          variation,
          quantity: 0,
        });
        const nextCart = this.carts.find(c => c.orderType !== this.cartOrderType);
        const response = await axios.get(`api/ot/${nextCart.orderType}/cart`);
        await this.setCurrentCart(response.data);
        await this.getCarts();
        this.setCartsLoadingStatus(false);
      }
    },

    async removeGiftCard(giftCardProfile) {
      try {
        await this.removeEGiftCard(giftCardProfile);
      } catch (error) {
        console.log(error);
      } finally {
        if (this.$configuration.orderTypesEnabled) {
          await this.getCarts();
        }
      }
    },

    /**
     * Takes in either a BigNumber or a Number and returns a Number.
     * @param {number|BigNumber} value
     * @return {number}
     */
    ensureNumber(value) {
      return value instanceof BigNumber ? value.toNumber() : value;
    },
  },
};
