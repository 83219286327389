/* eslint-disable class-methods-use-this */
import Vue from 'vue';
import VueGtag from 'vue-gtag';

import TrackableEventConstants from '@/constants/TrackableEventConstants';

export default new Vue({
  methods: {
    load(configuration, router) {
      this.googleAnalyticsId = configuration.googleAnalyticsId;

      if (this.googleAnalyticsId) {
        Vue.use(
          VueGtag,
          {
            config: {
              id: this.googleAnalyticsId,
              send_page_view: false,
            },
            ecommerce: {
              enabled: true,
              enhanced: true,
            },
            pageTrackerScreenviewEnabled: true,
            appName: 'WebCart',
          },
          router,
        );
      }
    },
    track(event, payload) {
      if (this.googleAnalyticsId) {
        switch (event) {
          case TrackableEventConstants.Search:
            this.$gtag.event(TrackableEventConstants.Search, {
              search_term: payload.query,
              items: payload.items?.map(item => this.itemToAnalyticsItem(item)),
            });
            break;

          case TrackableEventConstants.ItemImpression:
            if (payload.items?.length > 0) {
              this.$gtag.event(TrackableEventConstants.ItemImpression, {
                items: payload.items?.map(item => this.itemToAnalyticsItem(item)),
                item_list_id: payload.listId,
                item_list_name: payload.listName,
              });
            }
            break;

          case TrackableEventConstants.ItemViewed:
            this.$gtag.event(TrackableEventConstants.ItemViewed, {
              items: [this.itemToAnalyticsItem(payload.item)],
            });
            break;

          case TrackableEventConstants.ItemAddedToCart:
            this.$gtag.event(TrackableEventConstants.ItemAddedToCart, {
              items: payload.items?.map(item => this.itemToAnalyticsItem(item)),
              action: 'add',
              category: 'Orders',
            });
            break;

          case TrackableEventConstants.ItemRemovedFromCart:
            this.$gtag.event(TrackableEventConstants.ItemRemovedFromCart, {
              items: payload.items?.map(item => this.itemToAnalyticsItem(item)),
              action: 'remove',
              category: 'Orders',
            });
            break;

          case TrackableEventConstants.ItemAddedToList:
            this.$gtag.event(TrackableEventConstants.ItemAddedToList, {
              items: payload.items?.map(item => this.itemToAnalyticsItem(item)),
            });
            break;

          case TrackableEventConstants.CheckoutStarted:
            this.$gtag.event(TrackableEventConstants.CheckoutStarted, {
              items: payload.lineItems?.map(lineItem => this.lineItemToAnalyticsItem(lineItem)),
            });
            break;

          case TrackableEventConstants.CheckoutStageChanged:
            this.$gtag.event(TrackableEventConstants.CheckoutStageChanged, {
              checkout_step: payload.stage,
              items: payload.lineItems?.map(lineItem => this.lineItemToAnalyticsItem(lineItem)),
            });
            break;

          case TrackableEventConstants.OrderPlaced:
            this.$gtag.purchase({
              transaction_id: payload.scanCode,
              value: payload.orderTotal || payload.subtotal,
              currency: 'USD',
              tax: payload.taxTotal,
              shipping: payload.shippingTotal,
              coupon: payload.coupons?.map(coupon => coupon.code).join(','),
              items:
                payload.giftCardItems?.length > 0
                  ? payload.giftCardItems
                      .filter(giftCardItem => this.lineItemToAnalyticsItem(giftCardItem))
                      .map(giftCardItem => this.lineItemToAnalyticsItem(giftCardItem))
                  : payload.lineItems
                      .filter(lineItem => this.lineItemToAnalyticsItem(lineItem))
                      .map(lineItem => this.lineItemToAnalyticsItem(lineItem)),
            });
            break;

          case TrackableEventConstants.Refund:
            this.$gtag.purchase({
              transaction_id: payload.scancode,
              value: -payload.total,
              currency: 'USD',
              items: payload.lineItems?.map(lineItem => this.lineItemToAnalyticsItem(lineItem)),
            });
            break;

          default:
            this.$gtag.event(event, { ...payload });
            break;
        }
      }
    },
    itemToAnalyticsItem(item) {
      if (!item) {
        return undefined;
      }

      return {
        id: item.id,
        name: item.name,
        brand: item.brand,
        category: item.department,
        quantity: item.quantity || 1,
        price: item.actualPrice / (item.actualPriceDivider || 1.0),
      };
    },
    lineItemToAnalyticsItem(lineItem) {
      if (!lineItem || (lineItem.type && lineItem.type !== 1 && lineItem.type !== 60)) {
        return undefined;
      }

      return {
        id: lineItem.id || lineItem.item?.id,
        name: lineItem.item?.name || lineItem.detail,
        brand: lineItem.item?.brand,
        category: lineItem.item?.department,
        quantity: lineItem.quantity,
        currency: 'USD',
        price: lineItem.price || lineItem.extended || lineItem.amount,
        value: lineItem.price || lineItem.extended || lineItem.amount,
      };
    },
  },
});
