import Vue from 'vue';
import Vuex from 'vuex';
import user from './modules/user';
import browse from './modules/browse';
import cart from './modules/cart';
import lists from './modules/lists';
import items from './modules/items';
import scan from './modules/scan';

Vue.use(Vuex);

export const state = {
  config: {},
};

export const getters = {
  getConfiguration(state) {
    return state.config;
  },
  getCdn(state) {
    return state.config && state.config.cdn;
  },
  getRecaptchaKey(state) {
    if (state.config.socialProviders) {
      const recaptcha = state.config.socialProviders.find(
        ({ provider }) => provider === 'recaptcha',
      );

      return recaptcha ? recaptcha.authId : null;
    }

    return null;
  },
};

export const SET_CONFIGURATION = 'SET_CONFIGURATION';

export const mutations = {
  [SET_CONFIGURATION](state, config) {
    state.config = config;
  },
};

export const actions = {
  setConfiguration({ commit }, configuration) {
    commit(SET_CONFIGURATION, configuration);
  },
};

export const store = new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  modules: {
    user,
    browse,
    cart,
    lists,
    items,
    scan,
  },
});
