<template>
  <section class="wc-header__top border-bottom">
    <div class="d-flex justify-content-between px-3 container-xl">
      <WCEmployeeAssociationBanner v-if="isEmployee && isCustomer" />
      <div v-else-if="locationPhone && locationPhone.phone">
        <a :href="`tel:${locationPhone}`" class="d-block underline-link-from-center mx-3 my-2">
          <font-awesome-icon class="mr-1" icon="phone" />
          {{ $t('callUs') }}: {{ locationPhone }}
        </a>
      </div>
      <nav :aria-label="$t('main')" role="navigation" class="ml-auto">
        <ul class="nav">
          <li class="nav-item" v-if="isEmployee && !isCustomer">
            <router-link class="nav-link" to="/employee">{{ $t('employeeDashboard') }}</router-link>
          </li>
          <li class="nav-item">
            <router-link
              class="nav-link"
              :to="$configuration.pastOrdersEnabled ? '/me/orders' : '/me'"
              >{{ $t('yourAccount') }}</router-link
            >
          </li>
          <li class="nav-item" v-if="$configuration.listEnabled">
            <router-link class="nav-link" to="/me/lists">{{ $t('shoppingLists') }}</router-link>
          </li>
          <li
            class="nav-item"
            v-if="
              ($configuration.cartEnabled || isCustomerModeScan) &&
                !$configuration.orderTypesEnabled
            "
          >
            <router-link class="nav-link" to="/co">{{ $t('checkout') }}</router-link>
          </li>
          <li class="nav-item" v-if="$configuration.allowEGiftCards">
            <router-link class="nav-link" :to="giftCardUrl"
              ><font-awesome-icon icon="gift" /> {{ $t('giftCards') }}</router-link
            >
          </li>
          <WCLanguagesMenu />
        </ul>
      </nav>
    </div>
  </section>
</template>

<script>
import { getLocationPhone } from '@/utils';
import WCEmployeeAssociationBanner from '@/modules/employee/components/WCEmployeeAssociationBanner/WCEmployeeAssociationBanner.vue';
import GiftCardMixin from '@/modules/giftcard/mixins/GiftCardMixin';
import WCLanguagesMenu from '../WCLanguagesMenu/WCLanguagesMenu.vue';

export default {
  components: { WCEmployeeAssociationBanner, WCLanguagesMenu },
  props: {
    isEmployee: { type: Boolean },
    isCustomer: { type: Boolean },
    isCustomerModeScan: { type: Boolean },
  },
  computed: {
    locationPhone() {
      return getLocationPhone(this.$configuration.store);
    },
  },
  mixins: [GiftCardMixin],
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/_tools.scss';
@import '~@/assets/styles/_settings.scss';

.wc-header__top {
  background-color: var(--gray-200, $gray-200);
  ::v-deep a {
    color: var(--gray-700, $gray-700);

    &:hover {
      color: black;
    }
  }
  .underline-link-from-center {
    background-image: linear-gradient(var(--gray-700, $gray-700), var(--gray-700, $gray-700));
  }
}
</style>
