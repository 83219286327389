<template>
  <div class="p-1">
    <div
      class="justify-content-between m-2 small wc-ordertype-add-to-cart"
      v-for="orderType in orderTypes"
      :key="orderType.id"
    >
      <span class="wc-ordertype-label w-100 pr-2">{{ orderType.name }}</span>
      <WCAddToCartQuantityAdjuster
        :item="item"
        :linkButtonType="linkButtonType"
        :describedBy="describedBy"
        :orderType="orderType"
        :showMobileCustomize="showMobileCustomize"
        :isListItem="isListItem"
        :variation="variation"
        @addToCart="$emit('addToCart')"
        @decrement="$emit('decrement')"
      />
    </div>
  </div>
</template>

<script>
import OrderTypesMixin from '@/modules/orderTypes/mixins/OrderTypesMixin';

export default {
  name: 'WCOrderTypesAddToCartDropdown',
  props: {
    item: {
      type: Object,
      required: true,
    },
    orderTypes: {
      type: Array,
      required: true,
    },
    describedBy: {
      type: String,
    },
    linkButtonType: {
      type: Boolean,
      default: false,
    },
    showMobileCustomize: {
      type: Boolean,
    },
    variation: {},
    isListItem: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    WCAddToCartQuantityAdjuster: () =>
      import(
        '@/modules/cart/components/WCAddToCartQuantityAdjuster/WCAddToCartQuantityAdjuster.vue'
      ),
  },
  mixins: [OrderTypesMixin],
  computed: {
    eligibleInactiveOrderTypes() {
      return this.eligibleInactiveOrderTypesForItem(this.item);
    },
  },
};
</script>

<style scoped>
.wc-ordertype-label {
  overflow: hidden;
  text-overflow: ellipsis;
}

.wc-ordertype-add-to-cart {
  display: grid;
  grid-template-columns: 1fr 2fr;
}
</style>
