// Default product image placeholder
export const DEFAULT_PRODUCT_IMG = 'https://via.placeholder.com/150';

// Default Debounce Timelimit
export const DEFAULT_DEBOUNCE_TIME_LIMIT = 500;

// Search Filter Category items max limit
export const FILTER_ITEMS_MAX_LIMIT = 20;

// Search Filter Category item name - characters max limit for a long word
export const MAX_CHAR_LENGTH = 18;

// PWA Constants
export const PWA_CONSTANTS = {
  EXPIRY_DATE: 30,
  COOKIE_NAME: 'add-to-home-screen',
};

// Key Code Constants
export const KEYBOARD_CONSTANTS = {
  ARROW_LEFT: 37,
  ARROW_RIGHT: 39,
  ENTER: 13,
  HOME: 36,
  END: 35,
  TABINDEX_FOCUS: '0',
  TABINDEX_NO_FOCUS: '1',
};

// credit card
export const CREDIT_CARDS = [
  'visa',
  'stripe',
  'paypal',
  'mastercard',
  'jcb',
  'discover',
  'dinersclub',
  'applepay',
  'amex',
  'amazonpay',
];

// Account settings sections
export const ACCOUNT_SETTINGS_ROUTE_CMP_MAP = {
  profile: {
    name: 'Profile Information',
    route: 'profile',
    component: 'WCProfileInfoSectionForm',
  },
  work: {
    name: 'Work Information',
    route: 'work',
    component: 'WCWorkInfoSectionForm',
  },
  other: {
    name: 'Other Information',
    route: 'other',
    component: 'WCOtherInfoSectionForm',
  },
  email: {
    name: 'Email Information',
    route: 'email',
    component: 'WCEmailInfoSectionForm',
  },
  phone: {
    name: 'Phone Information',
    route: 'phone',
    component: 'WCPhoneInfoSectionForm',
  },
  address: {
    name: 'Address Information',
    route: 'address',
    component: 'WCAddressInfoSectionForm',
  },
};

// Account settings form field options
export const ACCOUNT_SETTINGS_OPTIONS = {
  REQUIRED: 'REQUIRED',
  OPTIONAL: 'OPTIONAL',
};

// Map api link
export const MAP_CONSTANTS = {
  GOOGLE_MAP_API: 'https://maps.google.com',
};

// User roles
export const USER_ROLES = {
  EMPLOYEE: 'employee',
  CUSTOMER: 'customer',
  EMPLOYEE_AS_CUSTOMER: 'employeeAsCustomer',
  GUEST: 'guest',
};
