<template>
  <div class="wc-cart-drawer shadow bg-white border d-flex flex-column">
    <div>
      <div class="d-flex justify-content-between align-items-center pt-3 px-3">
        <div class="d-flex align-items-center justify-content-between cart-header">
          <h3 class="font-size-xl mb-0 mr-3">{{ $t('shoppingCart') }}</h3>
          <span class="border rounded px-3 py-1 border-secondary font-weight-bold">
            {{ cartTotalDisplay | currency }}
          </span>
          <button
            class="btn btn-link wc-medium-btn"
            v-if="isFetched && hasLineItems && !isClearAllItemsSync && !isGuest && !hasCarts"
            @click="openSaveAllItesForLaterModal($refs.saveAllItesForLaterLBtnRef)"
            ref="saveAllItesForLaterLBtnRef"
          >
            <font-awesome-icon icon="bookmark" />
            {{ $t('saveAllItemsForLater') }}
          </button>
          <button
            class="btn btn-link wc-medium-btn"
            v-if="isFetched && (hasLineItems || isGiftCard) && !hasCarts"
            @click="openClearCartModal($refs.clearCartLBtnRef)"
            ref="clearCartLBtnRef"
          >
            <font-awesome-icon icon="cart-xmark" />
            {{ $t('clearCart') }}
          </button>
          <button
            class="btn btn-link wc-medium-btn"
            v-if="isFetched && (hasLineItems || isGiftCard) && hasCarts && !isScanAndGoMode"
            @click="openClearCartModal($refs.clearCartLBtnRef)"
            ref="clearCartLBtnRef"
          >
            <font-awesome-icon icon="cart-xmark" />
            {{ $t('clearCarts') }}
          </button>
          <!-- Mobile view only - show close button navigating to previous page -->
          <button
            class="unstyled-btn d-block d-lg-none"
            :aria-label="$t('goBack')"
            @click="$router.go(-1)"
          >
            <font-awesome-icon size="lg" icon="xmark" />
          </button>
        </div>
      </div>
      <hr class="border-secondary" />
      <div class="container-fluid font-size-xl" v-if="!isClearAllItemsSync">
        <WCCartDrawerCheckoutButton
          v-if="isFetched && (hasLineItems || isGiftCard) && (!hasCarts || isScanAndGoMode)"
          @click.native="close"
          ref="checkoutButton"
        />
      </div>
    </div>

    <WCSpinner v-if="!isFetched || isLoading" position="center" size="xl" />

    <!--Start Mobile save for later and clear cart buttons -->
    <div class="wc-cart-clear-save-btn-sm px-1">
      <button
        class="btn btn-link  wc-small-btn mr-auto"
        v-if="
          isFetched &&
            hasLineItems &&
            !isClearAllItemsSync &&
            !isGuest &&
            !hasCarts &&
            !isScanAndGoMode
        "
        @click="openSaveAllItesForLaterModal($refs.saveAllItesForLaterMBtnRef)"
        ref="saveAllItesForLaterMBtnRef"
      >
        <font-awesome-icon icon="bookmark" />
        {{ $t('saveAllItemsForLater') }}
      </button>
      <button
        class="btn btn-link wc-small-btn ml-auto"
        v-if="isFetched && (hasLineItems || isGiftCard) && !hasCarts"
        @click="openClearCartModal($refs.clearCartMBtnRef)"
        ref="clearCartMBtnRef"
      >
        <font-awesome-icon icon="cart-xmark" />
        {{ $t('clearCart') }}
      </button>
      <button
        class="btn btn-link wc-small-btn ml-auto"
        v-if="isFetched && (hasLineItems || isGiftCard) && hasCarts && !isScanAndGoMode"
        @click="
          clearAllCarts;
          openClearCartModal($refs.clearCartMBtnRef);
        "
        ref="clearCartMBtnRef"
      >
        <font-awesome-icon icon="cart-xmark" />
        {{ $t('clearCarts') }}
      </button>
    </div>
    <!--End Mobile save for later and clear cart buttons -->

    <!--Start Cart Drawer Items -->
    <div class="list-group list-group-flush wc-cart-drawer__wrapper flex-grow-1">
      <template v-if="!isClearAllItemsSync && isFetched && !isLoading">
        <!--Start Cart Drawer order type groups -->
        <WCCartDrawerOrderTypeGroup v-if="hasCarts && !isScanAndGoMode" :carts="carts" />
        <!--End Cart Drawer Order Type Groups -->
        <!--Start Cart Drawer Gift Cards -->
        <WCCartDrawerGiftCard v-else-if="isGiftCard" />
        <!--End Cart Drawer Gift Cards -->
        <!-- Start Cart Drawer Line Items -->
        <template v-else-if="hasLineItems">
          <div>
            <!--Start Cart Drawer out of stock alert -->
            <div
              class="container-fluid cart-item-border"
              v-if="outofstockLineItems && outofstockLineItems.length > 0"
            >
              <WCOutOfStockAlert :outofstockLineItems="outofstockLineItems" />
            </div>
            <!--End Cart Drawer out of stock alert -->

            <!--Start Cart Drawer line items -->
            <div v-if="hasLineItems">
              <div>
                <div
                  class="container-fluid cart-item-border "
                  v-for="lineItem in cartItems"
                  :key="lineItem.id"
                >
                  <div>
                    <WCCartDrawerItem
                      v-if="lineItem.type === 1"
                      v-show="lineItem.type === 1 && lineItem.quantity > 0"
                      class="list-group-item wc-cart-item "
                      :line-item="lineItem"
                    />
                  </div>
                </div>
              </div>

              <div
                class="container-fluid cart-item-border "
                v-for="(lineItem, index) in cartItems"
                :key="index"
              >
                <div>
                  <WCCartDrawerSpecialOrderItem
                    v-if="lineItem.type === 60"
                    class="list-group-item wc-cart-drawer-item-wrapper wc-cart-item"
                    :soItem="lineItem"
                  />
                </div>
              </div>
            </div>
            <!--End Cart Drawer out of stock alert -->
          </div>
        </template>
        <!-- End Cart Drawer Line Items -->
        <!--Start Empty Cart Drawer -->
        <WCEmptyCart v-else @close="close" />
        <!--End Empty Cart Drawer -->
      </template>

      <!--Start Cart Drawer Save For Later List -->
      <WCCartDrawerSaveForLaterList
        v-if="
          !isLoading &&
            isFetched &&
            detailList &&
            detailList.items &&
            detailList.items.length &&
            !isGiftCard &&
            !isGuest &&
            !isScanAndGoMode
        "
        :saveForLaterList="detailList"
      />
      <!--End Cart Drawer Save For Later List -->
    </div>
    <!-- End Cart Drawer Items -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import WCSpinner from '@/components/WCSpinner/WCSpinner.vue';
import ModalService from '@/modules/modals/services/modal.service';
import ToastService from '@/modules/toasts/services/ToastService';
import WCSimpleToast from '@/modules/toasts/components/WCSimpleToast/WCSimpleToast.vue';
import ScanAndGoMixin from '@/modules/user/mixins/ScanAndGoMixin';
import WCCartDrawerOrderTypeGroup from '@/modules/cart/components/WCCartDrawerOrderType/components/WCCartDrawerOrderTypeGroup.vue';
import WCCartDrawerSpecialOrderItem from '../WCCartDrawerSpecialOrderItem/WCCartDrawerSpecialOrderItem.vue';
import WCCartDrawerItem from '../WCCartDrawerItem/WCCartDrawerItem.vue';
import WCCartDrawerCheckoutButton from '../WCCartDrawerCheckoutButton/WCCartDrawerCheckoutButton.vue';
import WCEmptyCart from '../WCEmptyCart/WCEmptyCart.vue';
import CartDrawerService from '../../services/CartDrawerService';
import WCCartDrawerSaveForLaterList from '../WCCartDrawerSaveForLaterList/WCCartDrawerSaveForLaterList.vue';
import WCCartDrawerGiftCard from '../WCCartDrawerGiftCard/WCCartDrawerGiftCard.vue';
import WCOutOfStockAlert from '../WCOutOfStockAlert/WCOutOfStockAlert.vue';
import WCClearCartModal from '../WCClearCartModal/WCClearCartModal.vue';
import WCSaveAllItemsForLaterModal from '../WCSaveAllItemsForLaterModal/WCSaveAllItemsForLaterModal.vue';

// Clear all status for setting loader
const CLEAR_ALL_STATUS = {
  CLEAR_ALL_INIT: 'CLEAR_ALL_INIT',
  CLEAR_ALL_MODAL_OPEN: 'CLEAR_ALL_MODAL_OPEN',
  CLEAR_ALL_SYNC: 'CLEAR_ALL_SYNC',
  CLEAR_ALL_DONE: 'CLEAR_ALL_DONE',
};

export default {
  name: 'WCCartDrawer',
  props: {
    carts: {},
    lineItems: {
      required: false,
      type: Array,
    },
    outofstockLineItems: {
      type: Array,
    },
  },
  components: {
    WCCartDrawerSpecialOrderItem,
    WCCartDrawerItem,
    WCCartDrawerCheckoutButton,
    WCEmptyCart,
    WCSpinner,
    WCCartDrawerSaveForLaterList,
    WCCartDrawerGiftCard,
    WCOutOfStockAlert,
    WCCartDrawerOrderTypeGroup,
  },
  mixins: [ScanAndGoMixin],
  data() {
    return {
      isClearAllItems: CLEAR_ALL_STATUS.CLEAR_ALL_INIT,
      loading: false,
      allCarts: '',
    };
  },

  async mounted() {
    await this.fetch();

    if (this.$configuration.orderTypesEnabled && !this.isScanAndGoMode) {
      await this.getCarts({ loading: true });
    }
    if (this.isScanAndGoMode) {
      await this.reload();
    }
    if (!this.isGuest && !this.isGiftCard && (this.detailList || this.saveForLaterList)) {
      await this.loadDetails({ list: this.saveForLaterList });
    }

    if (this.$refs.checkoutButton) this.$refs.checkoutButton.$el.focus();
  },
  computed: {
    ...mapGetters({
      saveForLaterList: 'lists/getSaveForLaterList',
      isGuest: 'user/isGuest',
      isFetched: 'cart/isFetched',
      isGiftCard: 'cart/isGiftCard',
      getTotals: 'cart/getTotals',
      giftCardTotal: 'cart/getGiftCardTotals',
      detailList: 'lists/getDetailList',
      isLoading: 'cart/isLoading',
      isSyncing: 'cart/isSyncing',
      isScanAndGoMode: 'user/isCustomerModeScan',
      isCartsLoading: 'cart/cartsLoadingStatus',
    }),
    subTotal() {
      return this.getTotals && this.getTotals.sub;
    },
    cartTotalDisplay() {
      if (this.$configuration.orderTypesEnabled && !this.isScanAndGoMode) {
        return this.getMultipleCartsTotal;
      }
      return this.giftCardTotal ? this.giftCardTotal : this.subTotal;
    },
    isClearAllItemsSync() {
      return this.isClearAllItems === CLEAR_ALL_STATUS.CLEAR_ALL_SYNC;
    },
    cartItems() {
      if (this.carts?.length && this.lineItems.length < 1) {
        return this.carts[0].lineItems;
      }
      return this.lineItems;
    },
    getMultipleCartsTotal() {
      let total = 0;
      if (this.carts && this.carts.length) {
        this.carts.forEach(c => {
          total += c.subTotal;
        });
      }

      return total;
    },
    hasLineItems() {
      return this.cartItems && this.cartItems.length;
    },
    hasCarts() {
      return this.$configuration.orderTypesEnabled && this.carts?.length;
    },
  },

  methods: {
    ...mapActions({
      loadDetails: 'lists/loadDetails',
      fetch: 'lists/fetch',
      reload: 'cart/reload',
      getCarts: 'cart/getCarts',
      setCurrentCart: 'cart/setCurrentCart',
      setLoadingStatus: 'cart/setLoadingStatus',
      removeAllCartItems: 'cart/motRemoveAllCartItems',
    }),
    close() {
      CartDrawerService.close(this);
    },
    clearAllItems() {
      this.removeAllCartItems();
    },
    clearAllCarts() {
      this.allCarts = true;
      return this.allCarts;
    },
    async openClearCartModal(clearCartBtnRef) {
      this.isClearAllItems = CLEAR_ALL_STATUS.CLEAR_ALL_MODAL_OPEN;
      try {
        await ModalService.open(WCClearCartModal, {
          lineItems: this.lineItems,
          isGiftCard: this.isGiftCard,
          isGuest: this.isGuest,
          carts: this.carts,
          allCarts: this.allCarts,
        });
      } catch (error) {
        // NOOP
      } finally {
        if (clearCartBtnRef) clearCartBtnRef.focus();
      }
    },
    async openSaveAllItesForLaterModal(saveAllItesForLaterBtnRef) {
      this.isClearAllItems = CLEAR_ALL_STATUS.CLEAR_ALL_MODAL_OPEN;
      try {
        await ModalService.open(WCSaveAllItemsForLaterModal, {
          lineItems: this.lineItems,
          isGiftCard: this.isGiftCard,
          isGuest: this.isGuest,
          carts: this.carts,
          allCarts: this.allCarts,
        });
      } catch (error) {
        // NOOP
      } finally {
        if (saveAllItesForLaterBtnRef) saveAllItesForLaterBtnRef.focus();
      }
    },
  },

  watch: {
    /**
     * Watcher to check isSyncing property for clear cart functionality and update loading status
     */
    isSyncing() {
      if (!this.isSyncing && this.isClearAllItems === CLEAR_ALL_STATUS.CLEAR_ALL_SYNC) {
        this.isClearAllItems = CLEAR_ALL_STATUS.CLEAR_ALL_DONE;
        if (!this.lineItems || this.lineItems?.length === 0 || !this.isGiftCard) {
          ToastService.open(WCSimpleToast, {
            props: {
              variant: 'success',
              title: this.$t('clearCart'),
              message: this.$t('clearCartSuccess'),
            },
            timeout: 7000,
          });
        } else {
          ToastService.open(WCSimpleToast, {
            props: {
              variant: 'danger',
              title: this.$t('clearCart'),
              message: this.$t('clearCartFailed'),
            },
            timeout: 7000,
          });
        }
      } else if (this.isSyncing && this.isClearAllItems === CLEAR_ALL_STATUS.CLEAR_ALL_MODAL_OPEN) {
        this.isClearAllItems = CLEAR_ALL_STATUS.CLEAR_ALL_SYNC;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

.wc-cart-drawer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 3rem;
  width: 40rem;
  max-width: 100%;
  z-index: map-get($zindex, 'overlays');
}

.cart-header {
  width: 100%;
  h4 {
    margin-right: 1rem;
  }
}

.cart-spinner {
  min-height: 4rem;
}

.wc-medium-btn {
  display: block;
}

.wc-small-btn {
  display: none;
}

@include media-breakpoint-up(lg) {
  .wc-cart-drawer {
    bottom: rem(15px);
    top: rem(100px);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  .cart-header {
    width: 100%;

    h4 {
      margin-right: 0;
    }
  }
}

@include media-breakpoint-down(sm) {
  .wc-medium-btn {
    display: none;
  }

  .wc-small-btn {
    display: block;
  }
  .wc-cart-clear-save-btn-sm {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@include media-breakpoint-down(xs) {
  .wc-cart-item {
    padding: 0;
  }
}

.wc-cart-item {
  border: 0;
}

.wc-subtotal {
  padding: $pad-2;
}

.wc-cart-drawer__wrapper {
  overflow-y: auto;
}
.wc-save-for-later__wrapper {
  overflow-y: auto;
}

.back-btn--width {
  width: rem(70px);
}

@include media-breakpoint-down(xs) {
  .cart-item-border {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin: $margin-3 0;

    &:last-child {
      border: 0;
    }
  }
}
</style>
