import EmployeeDashboard from '@/layouts/Employee/EmployeeDashboard.vue';
import Home from '../../views/Home/Home.vue';
import AccountDashboard from '../../layouts/Account/AccountDashboard.vue';
import MobileSearchHeader from '../../layouts/MobileSearchHeader.vue';
import MobileAccountSubpage from '../../layouts/Account/MobileAccountSubpage.vue';
import HeaderlessMobile from '../../layouts/HeaderlessMobile.vue';
import MobileSubpageHeader from '../../layouts/MobileSubpageHeader.vue';
import MobileScanView from '../../layouts/MobileScanView.vue';
import requireCustomerLogin from '../route-guards/require-customer-login/require-customer-login';
import requireEmployeeLogin from '../route-guards/require-employee-login/require-employee-login';
import requireLogout from '../route-guards/require-logout/require-logout';
import guardCheckout from '../route-guards/guard-checkout/guard-checkout';
import rejectEmployeeAccess from '../route-guards/reject-employee-access/reject-employee-access';
import giftCardAccess from '../route-guards/gift-card-access/gift-card-access';
import shoppingListAccess from '../route-guards/shopping-list-access/shopping-list-access';
import walletAccess from '../route-guards/wallet-access/wallet-access';
import pastOrdersAccess from '../route-guards/past-orders-access/past-orders-access';
import pastItemsAccess from '../route-guards/past-items-access/past-items-access';
import rewardsAccess from '../route-guards/rewards-access/rewards-access';
import customerCardAccess from '../route-guards/customer-card-access/customer-card-access';
import searchAccess from '../route-guards/search-access/search-access';
import cartAccess from '../route-guards/cart-access/cart-access';
import scanAndGoAccess from '../route-guards/scan-and-go-access/scan-and-go-access';

export default [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      routeGuard: rejectEmployeeAccess,
      restrictScanAndGo: true,
    },
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      routeGuard: requireLogout,
    },
    component: () => import('../../modules/user/views/Login/Login.vue'),
  },
  {
    path: '/register',
    name: 'Register',
    meta: {
      routeGuard: requireLogout,
    },
    component: () => import('../../modules/user/views/Register/Register.vue'),
  },
  {
    path: '/register/check-email',
    name: 'CheckEmail',
    meta: {
      routeGuard: requireLogout,
    },
    component: () => import('../../modules/user/views/CheckEmail/CheckEmail.vue'),
  },
  {
    path: '/verify/:uuid',
    name: 'VerifyEmail',
    component: () => import('../../modules/user/views/VerifyEmail/VerifyEmail.vue'),
  },
  {
    path: '/b',
    name: 'Browse',
    meta: {
      routeGuard: rejectEmployeeAccess,
      restrictScanAndGo: true,
    },
    component: () => import('../../views/Browse/Browse.vue'),
  },
  {
    path: '/select-store',
    name: 'SelectStore',
    meta: {
      breadcrumbs: [
        {
          to: '/',
          text: 'Home',
        },
        {
          to: '/select-store',
          text: 'Store Locations',
        },
      ],
      routeGuard: rejectEmployeeAccess,
      restrictScanAndGo: true,
    },
    component: () => import('../../views/SelectStore/SelectStore.vue'),
  },
  {
    path: '/i/:id',
    name: 'Product Detail Page',
    meta: {
      routeGuard: rejectEmployeeAccess,
    },
    component: () => import('../../views/ProductDetailPage/ProductDetailPage.vue'),
  },
  {
    path: '/me',
    redirect: {
      name: 'Account Settings',
    },
  },
  {
    path: '/me/edit',
    name: 'Account Settings',
    meta: {
      layout: {
        desktop: AccountDashboard,
        mobile: MobileAccountSubpage,
      },
      breadcrumbs: [
        {
          to: '/me',
          text: 'Account',
        },
      ],
      routeGuard: requireCustomerLogin,
    },
    component: () => import('@/modules/user/views/AccountEdit/AccountEdit.vue'),
  },
  {
    path: '/me/edit/:id',
    name: 'Edit Account',
    meta: {
      layout: {
        mobile: MobileAccountSubpage,
      },
      routeGuard: requireCustomerLogin,
    },
    component: () => import('@/modules/user/views/MobileAccountEdit/MobileAccountEdit.vue'),
  },
  {
    path: '/me/card',
    name: 'Customer Card',
    meta: {
      layout: {
        desktop: AccountDashboard,
        mobile: MobileAccountSubpage,
      },
      breadcrumbs: [
        {
          to: '/me',
          text: 'Account',
        },
      ],
      routeGuard: customerCardAccess,
    },
    component: () => import('@/modules/user/views/Card/CustomerCard.vue'),
  },
  {
    path: '/me/orders',
    name: 'PastOrders',
    meta: {
      title: 'Order History',
      layout: {
        desktop: AccountDashboard,
        mobile: MobileAccountSubpage,
      },
      breadcrumbs: [
        {
          to: '/me',
          text: 'Account',
        },
        {
          to: '/me/orders',
          text: 'Order History',
        },
      ],
      routeGuard: pastOrdersAccess,
    },
    component: () => import('@/modules/orders/views/PastOrders/PastOrders.vue'),
  },
  {
    path: '/me/orders/:scancode',
    name: 'Order',
    meta: {
      title: 'Order Details',
      layout: {
        desktop: AccountDashboard,
        mobile: MobileAccountSubpage,
      },
      breadcrumbs: [
        {
          to: '/me',
          text: 'Account',
        },
        {
          to: '/me/orders',
          text: 'Order History',
        },
        {
          to: '/me/orders',
          text: 'Order Details',
        },
      ],
      routeGuard: requireCustomerLogin,
    },
    component: () => import('@/modules/orders/views/Order/Order.vue'),
  },
  {
    path: '/ty/:scancode',
    name: 'Thank You',
    meta: {
      title: 'Thank You',
      routeGuard: rejectEmployeeAccess,
    },
    component: () => import('@/modules/checkout/views/ThankYou/ThankYou.vue'),
  },
  {
    path: '/guest/orders/:scancode',
    name: 'GuestOrder',
    meta: {
      title: 'Order Details',
      breadcrumbs: [
        {
          to: '/guest',
          text: 'Guest',
        },
        {
          to: '/guest/orders',
          text: 'Order Details',
        },
      ],
      routeGuard: rejectEmployeeAccess,
    },
    component: () => import('@/modules/orders/views/GuestOrder/GuestOrder.vue'),
  },
  {
    path: '/cs/:id',
    name: 'CustomSource',
    meta: {
      routeGuard: rejectEmployeeAccess,
      restrictScanAndGo: false,
    },
    component: () => import('../../views/CustomSource/CustomSource.vue'),
    props: true,
  },
  {
    path: '*',
    name: 'NotFound',
    component: () => import('../../views/Errors/NotFound.vue'),
  },
  {
    path: '/forgot',
    name: 'ForgotPassword',
    meta: {
      routeGuard: requireLogout,
    },
    component: () => import('../../modules/user/views/Forgot/Forgot.vue'),
  },
  {
    path: '/forgot/:token',
    name: 'RecoverPassword',
    meta: {
      routeGuard: requireLogout,
    },
    component: () => import('../../modules/user/views/Recover/Recover.vue'),
    props: true,
  },
  {
    path: '/contact',
    name: 'ContactUs',
    meta: {
      breadcrumbs: [
        {
          to: '/',
          text: 'Home',
        },
        {
          to: '/contact',
          text: 'Contact us',
        },
      ],
      routeGuard: rejectEmployeeAccess,
      restrictScanAndGo: true,
    },
    component: () => import('../../views/Contact/ContactUs.vue'),
  },
  {
    path: '/me/password',
    name: 'Change Password',
    meta: {
      layout: {
        desktop: AccountDashboard,
        mobile: MobileAccountSubpage,
      },
      breadcrumbs: [
        {
          to: '/me',
          text: 'Account',
        },
        {
          to: '/me/password',
          text: 'Change Password',
        },
      ],
      routeGuard: requireCustomerLogin,
    },
    component: () => import('../../modules/user/views/ChangePassword/ChangePassword.vue'),
  },
  {
    path: '/co',
    name: 'Checkout',
    meta: {
      routeGuard: guardCheckout,
      restrictScanAndGo: true,
    },
    component: () => import('../../modules/checkout/views/Checkout/Checkout.vue'),
  },
  {
    path: '/scan/co',
    name: 'Checkout Review Items',
    meta: {
      routeGuard: guardCheckout,
      isScanAndGoPage: true,
      isProductReviewPage: true,
      restrictNonScanAndGo: true,
    },
    component: () => import('../../modules/checkout/views/Checkout/ScanCheckout.vue'),
  },
  {
    path: '/scan/co/cr',
    name: 'Checkout Cash',
    meta: {
      routeGuard: guardCheckout,
      isScanAndGoPage: true,
      isCashPage: true,
      restrictNonScanAndGo: true,
    },
    component: () => import('../../modules/checkout/views/Checkout/ScanCheckout.vue'),
  },
  {
    path: '/scan/co/pp',
    name: 'Checkout Payment',
    meta: {
      routeGuard: guardCheckout,
      isScanAndGoPage: true,
      isPaymentPage: true,
      restrictNonScanAndGo: true,
    },
    component: () => import('../../modules/checkout/views/Checkout/ScanCheckout.vue'),
  },
  {
    path: '/scan/co/pt',
    name: 'Checkout Payment Type',
    meta: {
      routeGuard: guardCheckout,
      isScanAndGoPage: true,
      isPaymentTypePage: true,
      restrictNonScanAndGo: true,
    },
    component: () => import('../../modules/checkout/views/Checkout/ScanCheckout.vue'),
  },
  {
    path: '/me/rewards',
    name: 'Rewards',
    meta: {
      layout: {
        desktop: AccountDashboard,
        mobile: MobileAccountSubpage,
      },
      breadcrumbs: [
        {
          to: '/me',
          text: 'Account',
        },
        {
          to: '/me/rewards',
          text: 'Rewards',
        },
      ],
      routeGuard: rewardsAccess,
    },
    component: () => import('../../views/Rewards/Rewards.vue'),
  },
  {
    path: '/me/wallet',
    name: 'Wallet',
    meta: {
      layout: {
        desktop: AccountDashboard,
        mobile: MobileAccountSubpage,
      },
      breadcrumbs: [
        {
          to: '/me',
          text: 'Account',
        },
        {
          to: '/me/wallet',
          text: 'Wallet',
        },
      ],
      routeGuard: walletAccess,
    },
    component: () => import('../../views/Wallet/Wallet.vue'),
  },
  {
    path: '/me/add-wallet',
    name: 'Add Wallet',
    meta: {
      layout: {
        desktop: AccountDashboard,
        mobile: MobileAccountSubpage,
      },
      breadcrumbs: [
        {
          to: '/me',
          text: 'Account',
        },
        {
          to: '/me/wallet',
          text: 'Wallet',
        },
        {
          to: '/me/add-wallet',
          text: 'Add Wallet',
        },
      ],
      routeGuard: walletAccess,
    },
    component: () => import('../../views/Wallet/WCWalletAddCard.vue'),
  },
  {
    path: '/me/items',
    name: 'Buy It Again',
    meta: {
      title: 'Buy It Again',
      layout: {
        desktop: AccountDashboard,
        mobile: MobileAccountSubpage,
      },
      breadcrumbs: [
        {
          to: '/me',
          text: 'Account',
        },
        {
          to: '/me/items',
          text: 'Buy It Again',
        },
      ],
      routeGuard: pastItemsAccess,
      restrictScanAndGo: true,
    },
    component: () => import('@/modules/user/views/BuyItAgain/BuyItAgain.vue'),
  },
  {
    path: '/employee/login',
    name: 'EmployeeLogin',
    meta: {
      routeGuard: requireLogout,
      restrictScanAndGo: true,
    },
    component: () => import('../../views/Employee/Login/EmployeeLogin.vue'),
  },
  {
    path: '/employee',
    redirect: {
      name: 'EmployeeCustomerSelect',
    },
  },
  {
    path: '/employee/cs',
    name: 'EmployeeCustomerSelect',
    meta: {
      title: 'Associate as customer',
      layout: {
        desktop: EmployeeDashboard,
      },
      breadcrumbs: [
        {
          to: '/employee',
          text: 'Employee Dashboard',
        },
        {
          to: '/employee/cs',
          text: 'Associate as customer',
        },
      ],
      routeGuard: requireEmployeeLogin,
      restrictScanAndGo: true,
    },
    component: () => import('../../views/Employee/CustomerSelect/EmployeeCustomerSelect.vue'),
    props: true,
  },
  {
    path: '/me/lists',
    name: 'Lists',
    meta: {
      title: 'Shopping Lists',
      layout: {
        desktop: AccountDashboard,
        mobile: MobileAccountSubpage,
      },
      breadcrumbs: [
        {
          to: '/me',
          text: 'Account',
        },
        {
          to: '/me/lists',
          text: 'Shopping Lists',
        },
      ],
      routeGuard: shoppingListAccess,
    },

    component: () => import('../../modules/lists/views/Lists.vue'),
  },
  {
    path: '/me/list/:id',
    name: 'List',
    meta: {
      title: 'Shopping List',
      layout: {
        desktop: AccountDashboard,
        mobile: MobileAccountSubpage,
      },
      breadcrumbs: [
        {
          to: '/me',
          text: 'Account',
        },
        {
          to: '/me/lists/',
          text: 'Shopping Lists',
        },
        {
          to: '/me/lists/list',
          text: 'Shopping List',
        },
      ],
      routeGuard: shoppingListAccess,
    },
    component: () => import('../../modules/lists/views/List.vue'),
  },
  {
    path: '/msearch',
    meta: {
      layout: {
        desktop: AccountDashboard,
        mobile: MobileSearchHeader,
      },
      breadcrumbs: [
        {
          to: '/msearch',
          text: 'Search',
        },
      ],
      routeGuard: searchAccess,
      restrictScanAndGo: true,
    },
    name: 'Mobile Search',
    component: () => import('@/modules/mobileSearch/views/MobileSearch.vue'),
  },
  {
    path: '/gc',
    name: 'Gift Card',
    meta: {
      title: 'Gift Card',
      layout: {
        mobile: MobileSubpageHeader,
      },
      routeGuard: giftCardAccess,
      restrictScanAndGo: true,
    },
    component: () => import('../../modules/giftcard/views/GiftCardBuilder.vue'),
  },
  {
    path: '/gc/check',
    name: 'Gift Card Check',
    meta: {
      title: 'Gift Card Check',
      layout: {
        mobile: MobileSubpageHeader,
      },
      routeGuard: giftCardAccess,
      restrictScanAndGo: true,
    },
    component: () => import('../../modules/giftcard/views/GiftCardCheck.vue'),
  },
  {
    path: '/gc/detail/:id',
    name: 'Gift Card Details',
    meta: {
      title: 'Gift Card Details',
      layout: {
        mobile: MobileSubpageHeader,
      },
      routeGuard: giftCardAccess,
      restrictScanAndGo: true,
    },
    component: () => import('../../modules/giftcard/views/GiftCardDetails.vue'),
  },
  {
    path: '/mb',
    name: 'MobileBrowse',
    meta: {
      layout: {
        mobile: MobileSearchHeader,
      },
      routeGuard: rejectEmployeeAccess,
      restrictScanAndGo: true,
    },
    component: () => import('../../views/Browse/Browse.vue'),
  },
  {
    path: '/mcart',
    name: 'Mobile Cart',
    meta: {
      layout: {
        mobile: HeaderlessMobile,
      },
      routeGuard: cartAccess,
    },
    component: () => import('../../views/MobileCart/MobileCart.vue'),
  },
  {
    path: '/me/programs',
    name: 'Customer Programs',
    meta: {
      layout: {
        desktop: AccountDashboard,
        mobile: MobileAccountSubpage,
      },
      breadcrumbs: [
        {
          to: '/me',
          text: 'Account',
        },
        {
          to: '/me/programs',
          text: 'Customer Programs',
        },
      ],
      routeGuard: requireCustomerLogin,
    },
    component: () => import('../../modules/user/views/CustomerPrograms/CustomerPrograms.vue'),
  },
  {
    path: '/customize/:id/:name/:orderType?',
    name: 'Customize Your Item',
    meta: {
      layout: {
        mobile: HeaderlessMobile,
      },
      routeGuard: rejectEmployeeAccess,
    },
    component: () =>
      import('../../modules/itemModifiers/views/CustomizeYourItems/CustomizeYourItems.vue'),
  },
  {
    path: '/ci/ty',
    name: 'Thank You!',
    meta: {
      title: 'Thank You!',
      layout: {
        mobile: MobileSubpageHeader,
      },
      routeGuard: rejectEmployeeAccess,
    },
    component: () => import('../../modules/checkin/views/checkinThankYou.vue'),
  },
  {
    path: '/ci/on-our-way',
    name: 'On Our Way',
    meta: {
      title: 'On Our Way',
      layout: {
        mobile: MobileSubpageHeader,
      },
      routeGuard: rejectEmployeeAccess,
    },
    component: () => import('../../modules/checkin/views/checkinOnOurWay.vue'),
  },
  {
    path: '/ci/oops',
    name: 'oops',
    meta: {
      title: 'oops',
      layout: {
        mobile: MobileSubpageHeader,
      },
      routeGuard: rejectEmployeeAccess,
    },
    component: () => import('../../modules/checkin/views/checkinOops.vue'),
  },
  {
    path: '/ci/:token',
    name: 'Check-In',
    meta: {
      title: 'Check-In',
      layout: {
        mobile: MobileSubpageHeader,
      },
      routeGuard: rejectEmployeeAccess,
    },
    component: () => import('../../modules/checkin/views/checkinPage.vue'),
  },
  // Route for initiate the scanner
  {
    path: '/scan',
    name: 'Scan Products',
    meta: {
      layout: {
        mobile: MobileScanView,
      },
      restricted: {
        desktop: true,
        mobile: false,
      },
      routeGuard: scanAndGoAccess,
      isScanAndGoPage: true,
      scanHomePage: true,
    },
    component: () => import('../../views/ScanProduct/ScanProduct.vue'),
  },
  {
    path: '/scan/list/:listId',
    name: 'Scan Shopping Products List',
    meta: {
      layout: {
        mobile: MobileScanView,
      },
      restricted: {
        desktop: true,
        mobile: false,
      },
      routeGuard: scanAndGoAccess,
      isScanAndGoPage: true,
      restrictNonScanAndGo: true,
    },
    component: () => import('../../views/ScanProduct/ScanProduct.vue'),
  },
  {
    path: '/scan/item/:id',
    name: 'Scanned Product Details',
    meta: {
      layout: {
        mobile: MobileScanView,
      },
      restricted: {
        desktop: true,
        mobile: false,
      },
      routeGuard: scanAndGoAccess,
      isScanAndGoPage: true,
      restrictNonScanAndGo: true,
    },
    component: () => import('../../views/ScanProduct/ScanProduct.vue'),
  },
  // Route for displaying product details
  {
    path: '/scan/item/:id/:listId',
    name: 'Scan Shopping Product List And Details',
    meta: {
      layout: {
        mobile: MobileScanView,
      },
      restricted: {
        desktop: true,
        mobile: false,
      },
      routeGuard: scanAndGoAccess,
      isScanAndGoPage: true,
      restrictNonScanAndGo: true,
    },
    component: () => import('../../views/ScanProduct/ScanProduct.vue'),
  },
  {
    path: '/scan/unavailable',
    name: 'Accelerated Check Out Unavailable',
    meta: {
      layout: {
        mobile: MobileScanView,
      },
      restricted: {
        desktop: true,
        mobile: false,
      },
      isScanAndGoPage: true,
    },
    component: () =>
      import('../../views/ScanProduct/ScanAndGoUnavailable/ScanAndGoUnavailable.vue'),
  },
  {
    path: '/scan/exit',
    name: 'Exit Accelerated Check Out',
    meta: {
      restricted: {
        desktop: false,
        mobile: false,
      },
      isScanAndGoPage: true,
      restrictNonScanAndGo: true,
    },
    component: () => import('../../views/ScanProduct/ExitScanAndGo/ExitScanAndGo.vue'),
  },
  {
    path: '/redirect-to/:storeUrl',
    redirect: to => {
      window.location.href = `/s/${to.params.storeUrl}`;
    },
  },
  {
    path: '/orders/:id',
    name: 'Shopping by Order Type',
    component: () => import('@/modules/orderTypes/views/OrderTypeHome/OrderTypeHome.vue'),
  },
];
