<template>
  <div>
    <h1 tabindex="-1" ref="focusFirst" class="sr-only">{{ name }} {{ $t('homePage') }}</h1>
    <WCProductRow
      class="my-md-3"
      v-for="row in rows"
      :key="row.id"
      :rowId="row.id"
      :rowName="row.name"
    />
  </div>
</template>

<script>
import WCProductRow from '@/components/WCProductRow/WCProductRow.vue';

export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    rows: {
      type: Array,
    },
    ads: {
      type: Array,
    },
  },
  components: { WCProductRow },
};
</script>

<style scoped></style>
