import axios from 'axios';
import { store } from '@/store';
import ToastService from '@/modules/toasts/services/ToastService';
import WCSimpleToast from '@/modules/toasts/components/WCSimpleToast/WCSimpleToast.vue';

/**
 * Method to Logout the user
 * @returns isUserLoggedOut - Boolean - Status to indicate if the user is successfully logged out.
 */
export default async function() {
  let isUserLoggedOut = false;
  try {
    await axios.post('/api/auth/logout');
    isUserLoggedOut = true;
    ToastService.open(WCSimpleToast, {
      props: {
        variant: 'success',
        title: 'Logged Out',
        message: 'You have been successfully logged out',
      },
      timeout: 7000,
    });
  } catch (error) {
    console.error(error);
  } finally {
    if (store.getters.getConfiguration.orderTypesEnabled) {
      store.dispatch('cart/getCarts');
    }
    store.dispatch('cart/reload');
    store.dispatch('lists/fetch');
  }
  return isUserLoggedOut;
}
